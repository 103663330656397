import $ from 'jquery';
import Helpers from '../helpers/util';

let $el = $('.brags');

class BragFacts {
	constructor() {
		this.$el = $el;
		this.$title = $el.find('.brags-title');

		this.doLayout();

		Helpers.pubsub.subscribe('onPageReady', function() {
			this.doLayout();
		}.bind(this));

		Helpers.pubsub.subscribe('onResize', function() {
			this.doLayout();
		}.bind(this));
	}

	doLayout() {
		this.$title.css('height', '');
		var maxHeight = Math.max.apply(null, this.$title.map(function () {
			return $(this).outerHeight();
		}).get());

		if (window.innerWidth > 767) {
			this.$title.css('height', maxHeight);
		}

	}
}

export default BragFacts;