import $ from 'jquery';
import SM from 'scrollmagic';
import Helpers from '../helpers/util';
import TweenMax from 'gsap';

let controller;
let clsTransparent = 'transparent';

class Animate {
	constructor() {
		controller = new SM.Controller({
			globalSceneOptions : {
				triggerHook : .75
			}
		});
		this.attachEvents();
	}

	attachEvents() {

		let $video = $('.video');
		this.animateVideo($video);

		let $grid = $('.grid');
		this.animateGrid($grid);

		let $brags = $('.brags');
		this.animateBrags($brags);
	}

	animateVideo($el) {
		let $videoText = $el.find('.js-video-text');
		let $videoLine = $el.find('.js-video-line');
		$videoText.addClass(clsTransparent);
		Helpers.pubsub.subscribe('onPageReady', function() {
			TweenMax.staggerFromTo($videoText, 0.7, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, 0.3);
			TweenMax.to($videoLine, 0.3, { delay: 0.3, x: '100%' });
		});
	}

	animateGrid($el) {
		$el.find('.js-article').addClass(clsTransparent);

		Helpers.pubsub.subscribe('onPageReady', function() {
			$el.each(function() {
				let $article = $(this).find('.js-article');
				let $line = $(this).find('.js-article-line');
				new SM.Scene({
					triggerElement : $(this).get(0),
					duration : 0,
					reverse : false
				}).on('enter', function() {
					TweenMax.staggerFromTo($article, 1, { opacity: 0, y: 60 }, { opacity: 1, y: 0 }, 0.3);
					$line.each(function(i) {
						let delay = (i + 1) * 0.3;
						TweenMax.to($line.eq(i), 0.3, { delay, x: '100%' });
					});
				}).addTo(controller);
			});
		});
	}

	animateBrags($el) {
		let $brags = $el.find('.js-brags');
		let $heading = $el.find('.js-brags-heading');

		$brags.addClass(clsTransparent);
		$heading.addClass(clsTransparent);

		Helpers.pubsub.subscribe('onPageReady', function() {

			new SM.Scene({
				triggerElement : $el.get(0),
				duration : 0,
				reverse : false
			}).on('enter', function() {
				TweenMax.fromTo($heading, 1, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, 0.3);
				TweenMax.staggerFromTo($brags, 1, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, 0.3);
			}).addTo(controller);



		});
	}


}

export default Animate;