import $ from 'jquery';
import TweenMax from 'gsap';
import Helpers from '../helpers/util';

// Variables
let $body = $('body');
let imagesLoadedBool = false;

class Loader {
	constructor() {
		this.$images = $('[data-preload][data-src!=""]');
		this.length = this.$images.length;
		this.$navbar = $('.nav-bar');
		this.$nav = $('.nav');
		this.$loader = $('.loader');

		this.currentAsset = 0;
		this.assetsToLoadArray = [];
		this.totalAssets = 0;

		this.prepareImages();

		Helpers.dom.onTransitionEndAlways(this.$navbar, function() {
			if (this.currentAsset === this.totalAssets) {
				this.onImagesLoaded();
			}
		}.bind(this));
	}

	prepareImages() {
		if (this.$images.length) {
			this.totalAssets = this.$images.length;
			this.$images.each(function(i, v) {
				this.assetsToLoadArray.push(v);
			}.bind(this));
			this.preloadImages();
		}
	}

	preloadImages() {
		let $img = $(this.assetsToLoadArray[this.currentAsset]);

		let imgObj = new Image();
		let src = $img.attr('data-src');
		imgObj.src = src;
		imgObj.onload=function() {
			$img.parent().css('background-image', 'url("' + src + '")');
			this.preloadNextAsset();
		}.bind(this);
	}

	preloadNextAsset() {
		this.currentAsset++;
		this.updateNav();
		if(this.currentAsset === (this.totalAssets)) {
			//this.onImagesLoaded();
		} else {
			this.preloadImages();
		}
	}

	onImagesLoaded() {
		if (imagesLoadedBool) {
			return false;
		}
		imagesLoadedBool = true;
		$body.removeClass('loading');
		TweenMax.to(this.$loader, 1.2, { delay: 0.3, ease: window.Power3.easeInOut, opacity: 0, onComplete: function() {
			$body.addClass('ready');
			Helpers.pubsub.publish('onPageReady');
		}});
	}

	updateNav() {
		let position = this.currentAsset / this.length;
		let percent = position * 100;
		TweenMax.set(this.$navbar, { x: percent  + '%' });
	}
}

export default Loader;